import { interactionsSlice } from "../../../../../redux/components/customersSlice";
import * as requestFromServer from "./customersCrud";
import { callTypes, customersSliceBoards } from "./customersSlice";

const { actions } = customersSliceBoards;
export const fetchBoardDetail = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.customerDetail({ customerForDetail: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBoardDetailById(id)
    .then((response) => {
      const BoardDetail = response.data;
      dispatch(actions.customerDetail({ customerForDetail: BoardDetail }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const fetchBoardInfo = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.customerInfo({ customerForInfo: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBoardInfoById(id)
    .then((response) => {
      const BoardInfo = response.data;
      dispatch(actions.customerInfo({ customerForInfo: BoardInfo }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const fetchBoardTypes = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBoardTypes()
    .then((response) => {
      const BoardTypes = response.data;
      dispatch(actions.customerDetail({ customerForDetail: BoardTypes }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const fetchCustomers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.customersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const fetchCustomer = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then((response) => {
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const fetchGenerateToken =
  (id, queryParams, type = 1) =>
    async (dispatch) => {
      if (!id) {
        return dispatch(
          actions.customerGenerateToken({ customerForEdit: undefined })
        );
      }
      dispatch(actions.startCall({ callType: callTypes.action }));
      try {
        let board
        if (type == 1) {
          const response = await requestFromServer.getCustomerByToken(id, queryParams)
          board = response.data
          console.log("CONSOLE RESPONSE: ", response)
        } else {
          console.log("TIPO FIXO");
          const response = await requestFromServer.getCustomerById(id);
          board = response.data;
        }
        let res = {
          type_of_access: board.type,
          id_board_server: id,
          name: board.name,
          mode_server: true,
          type_qr: 2,
          pass: 0,
        }

        dispatch(actions.customerGenerateToken({ customerForEdit: { customer: res } }));
      } catch (error) {
        error.clientMessage = "Can't find customer";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      }
      // if (type == 1) {
      //   return requestFromServer
      //     .getCustomerByToken(id, queryParams)
      //     .then((response) => {
      //       const customer = response.data;
      //       // dispatch(
      //       //   actions.customerGenerateToken({ customerForEdit: customer })
      //       // );
      //     })
      //     // .catch((error) => {
      //     //   error.clientMessage = "Can't find customer";
      //     //   dispatch(actions.catchError({ error, callType: callTypes.action }));
      //     // });
      // } else {
      //   console.log("TIPO FIXO");

      //   const board = requestFromServer.getCustomerById(id)
      //     .then((response) => {
      //       console.log("CONSOLE RESPONSE: ", response)
      //     })
      //     .catch((error) => console.log("ERRO DO RESPONSE: ", error))
      //   console.log("CONSOLE BOARD: ", board)
      //   let res = {
      //     password: board.password,
      //     type_of_access: 1,
      //     id_board_server: id,
      //     name: "Outra String",
      //     mode_server: true,
      //     type_qr: 2,
      //     pass: 0,
      //   }
      //   dispatch(
      //     actions.customerGenerateToken({ customerForEdit: { customer: res } })
      //   );

      // }
    };

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerDeleted({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const deletePermanentCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePermanentCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerPermanentDeleted({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const resetCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .resetCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerReset({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const restartCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .restartCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerRestart({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const infoCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .infoCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerInfo({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const factoryCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .factoryCustomer(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerFactory({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const getData = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getData(id)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerGetData({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const createCustomer = (customerForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation)
    .then((res) => {
      const { customer } = res.data;
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerCreated({ customer }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const clearCustom = () => (dispatch) => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.customerClear());
};

export const clearQr = () => (dispatch) => {
  return dispatch(actions.customerClearQr());
};

export const updateCustomer = (customer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(customer)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customerUpdated({ customer }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const updateCustomersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const deleteCustomers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then((res) => {
      let message = res.data.message
        ? res.data.message
        : "Success in the operation!";
      let variant = res.status === 200 ? "success" : "danger";
      dispatch(actions.customersDeleted({ ids }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message,
            variant,
          },
        })
      );
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};

export const checkInformation = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .checkInformation(id)
    .then((response) => {
      const { customer } = response.data;
      dispatch(actions.customerCheckInformation({ customer }));
    })
    .catch((error) => {
      const message = error?.response?.data?.message
        ? error.response.data.message
        : "Não foi possível processar informação";

      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message,
            variant: "danger",
          },
        })
      );
    });
};
