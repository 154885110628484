import axios from "axios";

export const CUSTOMERS_URL = process.env.REACT_APP_PUBLIC_URL + "/types-report"; //all

// CREATE =>  POST: add a new customer to the server

/*export function createCustomer(customer) {
  return axios.post(`${CUSTOMERS_URL}/register`, { customer });
}*/

// READ
export function getAllCustomers(id) {
  return axios.post(`${CUSTOMERS_URL}/all`, { id_board: id });
}

/*export function getBoardDetailById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/detail/${customerId}`);
}

export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
}


export function getCustomerByToken(customerId, queryParams) {
  return axios.post(`${CUSTOMERS_URL}/token/${customerId}`, { queryParams });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status,
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
}

// DELETE => delete the customer from the server
export function resetCustomer(customerId) {
  return axios.post(`${CUSTOMERS_URL}/reset/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
}

export function checkInformation(customerId) {
  return axios.post(`${CUSTOMERS_URL}/check-password-enable/${customerId}`);
}*/
