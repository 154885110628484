/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

// import { useHistory } from 'react-router-dom';
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const { user } = useSelector((state) => state.auth);
  // const history = useHistory();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-closed `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/management/board-monitoring"
          >
            <span className="svg-icon menu-icon svg-icon-white">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Saturation.svg")}
              />
            </span>
            <span className="menu-text">
              <FormattedMessage id="GENERAL.BOARD.MONITORING"></FormattedMessage>
            </span>
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/board-monitoring"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/management/board-monitoring"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="GENERAL.BOARD.MONITORING.WATER"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {/* {user.isAdmin && ( */}
          <NavLink className="menu-link menu-toggle" to="/management/companies">
            <span className="svg-icon menu-icon svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.COMPANY"></FormattedMessage>
            </span>
          </NavLink>
          {/* )} */}

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/companies"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/companies">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.COMPANY.SUB"></FormattedMessage>
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/companies-link-users"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/management/companies-link-users"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.COMPANY.USER"></FormattedMessage>
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/companies-link-boards"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/management/companies-link-boards"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.COMPANY.BOARD"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/*begin::1 Level */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {user.isAdmin && (
            <NavLink
              className="menu-link menu-toggle"
              to="/management/permissions"
            >
              <span className="svg-icon menu-icon svg-icon-white ">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="MENU.PERMISSION"></FormattedMessage>
              </span>
            </NavLink>
          )}
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/permissions"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink className="menu-link" to="/management/permissions">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.PERMISSIONS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/roles-link-permissions"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/roles-link-permissions"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.ROLES.LINK.PERMISSIONS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/users-link-permissions"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/users-link-permissions"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.USERS.LINK.PERMISSIONS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/roles"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink className="menu-link" to="/management/roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.ROLES"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/users-link-roles"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/users-link-roles"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.USERS.PERFIL"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {/* {user.isAdmin && ( */}
          <NavLink className="menu-link menu-toggle" to="/management/users">
            <span className="svg-icon menu-icon svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.MANAGEMENTT"></FormattedMessage>
            </span>
          </NavLink>
          {/* )} */}
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/users"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink className="menu-link" to="/management/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.USER"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/boards">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.BOARDS"></FormattedMessage>
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards-link-users"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/management/boards-link-users"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.USERBOARD"></FormattedMessage>
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards-notifications"
                )}`}
                aria-haspopup="true"
              >
                {/* {user.isAdmin && ( */}
                <NavLink
                  className="menu-link"
                  to="/management/boards-notifications"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.BOARDS.NOTIFICATIONS"></FormattedMessage>
                  </span>
                </NavLink>
                {/* )} */}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards-link-tokens"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/boards-link-tokens"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.TOKEN.BOARDS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              {user.isAdmin && (
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/management/boards-control-boards"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/management/boards-control-boards"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="MENU.BOARDS.CONTROL.BOARDS"></FormattedMessage>
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/management/boards-block-list"
                    )}`}
                    aria-haspopup="true"
                  >
                    {user.isAdmin && (
                      <NavLink
                        className="menu-link"
                        to="/management/boards-block-list"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="GENERAL.BLOCKLIST.LIST"></FormattedMessage>
                        </span>
                      </NavLink>
                    )}
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/management/boards-console"
                    )}`}
                    aria-haspopup="true"
                  >
                    {user.isAdmin && (
                      <NavLink
                        className="menu-link"
                        to="/management/boards-console"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          <FormattedMessage id="GENERAL.BOARDS.CONSOLE"></FormattedMessage>
                        </span>
                      </NavLink>
                    )}
                  </li>
                </>
              )}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {user.isAdmin && (
            <NavLink className="menu-link menu-toggle" to="/management/groups">
              <span className="svg-icon menu-icon svg-icon-white">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Git2.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="MENU.GROUPS"></FormattedMessage>
              </span>
            </NavLink>
          )}
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/groups"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink className="menu-link" to="/management/groups">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.GROUPS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards-link-groups"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/boards-link-groups"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.BOARDS.LINK.GROUPS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {/* {user.isAdmin && ( */}
          <NavLink
            className="menu-link menu-toggle"
            to="/management/boards-link-packages"
          >
            <span className="svg-icon menu-icon svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Sketch.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.BENEFTS"></FormattedMessage>
            </span>
          </NavLink>
          {/* )} */}
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">eCommerce</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/boards-link-packages"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/management/boards-link-packages"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="GENERAL.PAKAGES.BOARD"></FormattedMessage>
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/packages-plans"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/packages-plans"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="GENERAL.PACKAGES.PLANS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/benefits-plans"
                )}`}
                aria-haspopup="true"
              >
                {user.isAdmin && (
                  <NavLink
                    className="menu-link"
                    to="/management/benefits-plans"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.BENEFITS"></FormattedMessage>
                    </span>
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
