import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { CustomersDetail } from "../app/modules/Management/pages/boards/customers-detail/CustomersDetail";
import { CustomersInfo } from "../app/modules/Management/pages/boards/customers-info/CustomersInfo";
import { AlertUser } from "../redux/components/Alert";
import { endSocket } from "./modules/Management/pages/boards-console/customer-socket-io/CustomerSocketIo";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DashboardTemplatePage } from "./pages/DashboardTemplatePage";
import { MyPage } from "./pages/MyPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/Management/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const location = useLocation();
  // TODO: fix exclude path with new logic
  useEffect(() => {
    const excludeList = ["/boards-console", "/board-monitoring"];
    const pathName = location.pathname;
    let isExcluded = false;

    excludeList.forEach((currentPath) => {
      if (pathName.includes(currentPath)) {
        endSocket({
          persistReference: currentPath
        })
        isExcluded = true;
      }
    });

    if (!isExcluded) {
      endSocket({})
    }
  }, [location]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AlertUser root={true} />
      <Switch>
        {
          /* Redirect from root URL to /dashboard. 
          Alterado para produção
          */
          <Redirect exact from="/" to="/management/boards" />
        }

        <ContentRoute path="/template" component={DashboardTemplatePage} />

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/management" component={ECommercePage} />
        <Route path="/detail/:id" component={CustomersDetail} />
        <Route path="/info/:id" component={CustomersInfo} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
