import moment from "moment";

export const getValues = (string) => {
  return string
    .substring(1)
    .split("&")
    .reduce((r, pair) => {
      let [key, value] = pair.split("=");
      value = decodeURIComponent(value);
      r[key] = value.includes("=") ? getValues(value) : value;
      return r;
    }, {});
};

export const removeObjectEmpty = (obj) => {
  Object.keys(obj).forEach(
    (k) => [undefined, "", null].includes(obj[k]) && delete obj[k]
  );
  return obj;
};

export const urlCreateParams = (objectParams) => {
  let historyObject = removeObjectEmpty(objectParams);
  let newUrl = new URLSearchParams(historyObject)?.toString();

  return historyObject ? `?${newUrl}` : "";
};

export const urlClean = (url, param) => {
  if (url.length < 0) {
    return url;
  }

  const iStart = url.indexOf(param);
  const iEnd = url.indexOf("&", iStart);
  let slicedStr = (
    url.slice(0, iStart) + url.slice(iEnd === -1 ? url.length : iEnd)
  ).replace("?&", "?");
  const lastIndex = slicedStr.lastIndexOf("&");
  if (lastIndex === slicedStr.length - 1) {
    slicedStr = slicedStr.slice(0, lastIndex);
  }

  return slicedStr;
};

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export async function mountTable(rows, columns) {
  let mountedRow = [];
  let mountedColumn = [];

  columns.forEach((column) => {
    const temp = column.label;
    mountedColumn.push(temp);
  });

  rows.forEach((element) => {
    let temp = [];
    columns.forEach((column) => {
      Object.entries(element).forEach((el) => {
        if (column.id === el[0]) {
          temp.push(el[1] ? el[1] : "");
        }
      });
    });

    mountedRow.push(temp);
  });

  return { mountedRow, mountedColumn };
}

export const isKeyValid = (objectArr) => {
  let keys = {};

  if (!objectArr || objectArr?.length < 0) {
    return keys;
  }

  objectArr.forEach((entity) => {
    Object.keys(entity).forEach((key) => {
      let isExist = Object.keys(keys).find((k) => k === key);
      if (!isExist) {
        keys = { ...keys, [key]: true };
      }
    });
  });

  return keys;
};

export function removeEmptyObjectsFromArray(array) {
  return array.filter((e) => {
    if (e && Object.keys(e).length !== 0) {
      return true;
    }
    return false;
  });
}

// console.log(getValues("?id_board=62b38569ca075c7aee22a523&maçan=testando"));
