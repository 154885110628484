import React from "react";
import ApexChart from "react-apexcharts";

export function Demo1Dashboard() {
  const options = {
    legend: {
      labels: {
        colors: "#777",
      },
    },
  };

  const dayChartData = {
    options,
    series: [44, 55, 13, 43],
  };

  const weekChartData = {
    options: {
      ...options,
      xaxis: {
        categories: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
      },
    },
    series: [{ name: "Série A", data: [30, 45, 25, 20, 35, 40, 50] }],
  };

  const monthChartData = {
    options: {
      ...options,
      xaxis: {
        categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Agost"],
      },
    },
    series: [
      {
        name: "Série A",
        data: [150, 200, 180, 250, 280, 220, 900, 2313123212000],
      },
    ],
  };

  const yearChartData = {
    options: {
      ...options,
      xaxis: {
        categories: ["2020", "2021", "2022", "2023"],
      },
    },
    series: [{ name: "Série A", data: [800, 1000, 1200, 900] }],
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <ApexChart
          type="pie"
          series={dayChartData.series}
          options={dayChartData.options}
          width="100%"
          height="250"
        />
        <p>Gráfico do Dia</p>
      </div>
      <div className="col-md-6">
        <ApexChart
          type="line"
          series={weekChartData.series}
          options={weekChartData.options}
          width="100%"
          height="250"
        />
        <p>Gráfico da Semana</p>
      </div>
      <div className="col-md-6">
        <ApexChart
          type="area"
          series={monthChartData.series}
          options={monthChartData.options}
          width="100%"
          height="250"
        />
        <p>Gráfico do Mês</p>
      </div>
      <div className="col-md-6">
        <ApexChart
          type="bar"
          series={yearChartData.series}
          options={yearChartData.options}
          width="100%"
          height="250"
        />
        <p>Gráfico do Ano</p>
      </div>
    </div>
  );
}
