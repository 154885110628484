/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../../../_metronic/layout";
import { getValues } from "../../../../../../helpers";
import * as boardActions from "../../../_redux/boards/customersActions";
import * as typeReportActions from "../../../_redux/type-report/customersActions";

export function CustomersInfo({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();
  let defaultUrl = history?.location?.search;

  // Tabs
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const { actionsLoading, customerForInfo } = useSelector(
    (state) => ({
      actionsLoading: state.boards.actionsLoading,
      customerForInfo: state.boards.customerForInfo,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log(history?.location);
    console.log(defaultUrl);
    let urlObject = getValues(defaultUrl);
    console.log(urlObject);

    dispatch(boardActions.fetchBoardInfo(`${id}${defaultUrl}`));
  }, [id, dispatch]);

  useEffect(() => {
    if (customerForInfo) {
      setData(customerForInfo);
    }
  }, [customerForInfo]);

  useEffect(() => {
    let _title = id ? "" : "Detail";
    if (customerForInfo && id) {
      _title = `Info`;
    }
    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerForInfo, id]);

  return (
    <>
      <Card>
        {actionsLoading && <ModalProgressBar />}

        <CardBody>
          <div className="d-flex flex-column">
            <h6>Info da placa:</h6>
            <SyntaxHighlighter
              language="json"
              style={highlightStyle}
              customStyle={{
                background: `none transparent !important`,
              }}
            >
              {JSON.stringify(data, null, 2)}
            </SyntaxHighlighter>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
