import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  customer: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const customersSliceBoardsPermissions = createSlice({
  name: "permissions",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    customerFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },
    // findCustomers
    customersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCustomer
    customerCreated: (state, action) => {
      const { customer } = action.payload;
      console.log("customer created", customer)
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(customer);
      state.customer = customer;
    },
    // updateCustomer
    customerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.customer.id) {
          return action.payload.customer;
        }
        return entity;
      });
    },
    // deleteCustomer
    customerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteCustomers
    customersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // customersUpdateState
    customersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});




// export function CustomersPermissions({ history, match: { params: { id } } }) {
//   const subheader = useSubheader(); // Corrija a variável para "subheader" em vez de "suhbeader"
//   const { user } = useSelector((state) => state.auth);
//   const intl = useIntl();
//   const params = new URLSearchParams(history.location.search);
//   const id_board = params.get("id_board");
//   console.log(id_board);
//   const id_user = params.get("id_user");
//   console.log(id_user);

//   const [title, setTitle] = useState("");
//   const dispatch = useDispatch();
//   const { actionsLoading, customerForDetail } = useSelector(
//     (state) => ({
//       actionsLoading: state.boardspermissions.actionsLoading,
//       customerForDetail: state.boardspermissions.customerForDetail,
//     })
//   );


//   useEffect(() => {
//     dispatch(action.fetchCustomers({id_board,id_user}));
//   }, [id,dispatch]);

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   Checkbox,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   FormHelperText,
//   FormLabel,
// } from "@material-ui/core";
// import { FormattedMessage } from "react-intl";
// import * as action from "../../../_redux/boards-permissions/customersActions";
// import { useSubheader } from "../../../../../../_metronic/layout";
// import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

// export function CustomersPermissions({ history, match: { params: { id } } }) {
//   const suhbeader = useSubheader(); 
//   const dispatch = useDispatch();
//   const { actionsLoading, customerForDetail } = useSelector((state) => ({
//     actionsLoading: state.boardspermissions.actionsLoading,
//     customerForDetail: state.boardspermissions.customerForDetail,
//   }));

//   useEffect(() => {
//     dispatch(action.fetchCustomers({ id }));
//   }, [id, dispatch]);

//   useEffect(() => {
//     let _title = id ? "" : "Detail";
//     if (customerForDetail && id) {
//       _title = `Detail ${customerForDetail.name} `;
//     }
//     suhbeader.setTitle(_title);
//   }, [customerForDetail, id, suhbeader]);

//   const handlePermissionChange = (category, permissionKey) => (event) => {
//     // Lógica para lidar com a mudança na permissão
//   };

//   return (
//     <Card>
//       {actionsLoading && <ModalProgressBar />}
//       <CardHeader title={<FormattedMessage id="GENERAL.BOARD.PERMISSIONS" />} />
//       <CardContent>
//         <div className="d-flex flex-column">
//           <FormControl component="fieldset">
//             <FormLabel component="legend">Assign responsibility</FormLabel>
//             <FormGroup>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={false} // Coloque o valor correto da permissão
//                     onChange={handlePermissionChange("drive", "AC1")}
//                   />
//                 }
//                 label="AC1"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={false} // Coloque o valor correto da permissão
//                     onChange={handlePermissionChange("drive", "AC2")}
//                   />
//                 }
//                 label="AC2"
//               />
//               {/* Adicione mais checkboxes para outros tipos de permissão */}
//             </FormGroup>
//             <FormHelperText>Be careful</FormHelperText>
//           </FormControl>
//         </div>
//       </CardContent>
//     </Card>
//   );
// }

// export default CustomersPermissions;


/// parei aqui
{/* <Card>
<CardHeader title={<FormattedMessage id="GENERAL.BOARD.PERMISSIONS" />} />
<CardBody>
  <div className="d-flex flex-column">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <div className="checkbox-container">
          <FormControlLabel control={<Checkbox />} label="drive" />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="checkbox-container">
          <FormControlLabel control={<Checkbox />} label="scheduling" />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="checkbox-container">
          <FormControlLabel control={<Checkbox />} label="input" />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="checkbox-container">
          <FormControlLabel control={<Checkbox />} label="Checkbox 4" />
        </div>
      </Grid>
    </Grid>
  </div>
</CardBody>
</Card> */}