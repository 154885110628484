/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ApexChart from "react-apexcharts";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../../../_metronic/layout";
import { formatDate } from "../../../../../../helpers";
import * as boardActions from "../../../_redux/boards/customersActions";
import * as typeReportActions from "../../../_redux/type-report/customersActions";

export function CustomersDetail({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();
  // Tabs
  const [tab, setTab] = useState("basic");
  const [typesOfReport, setTypesOfReport] = useState([]);
  const [title, setTitle] = useState("");
  const [chartSelectedIndex, setChartSelectedIndex] = useState(-1);
  const [isShowModal, setIsShowModal] = useState(false);
  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: "pie",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            setChartSelectedIndex(config?.dataPointIndex);
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, customerForDetail, customerForTypeReportDetail } =
    useSelector(
      (state) => ({
        actionsLoading: state.boards.actionsLoading,
        customerForDetail: state.boards.customerForDetail,
        customerForTypeReportDetail:
          state.typeReport.customerForTypeReportDetail,
      }),
      shallowEqual
    );

  //Get Current Date
  const currentISODateFormatted = () => {
    return new Date().toISOString().slice(0, 16);
  };
  //Get Current Date - 7
  const generateInitialDate = () => {
    const today = new Date();
    const initialDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    return initialDate.toISOString().slice(0, 16);
  };

  useEffect(() => {
    dispatch(boardActions.fetchBoardDetail(id));
    dispatch(typeReportActions.fetchTypeReportDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (customerForDetail) {
      setData({
        series: customerForDetail?.links?.map((link) => {
          return link.count;
        }),
        options: {
          ...data.options,
          labels: customerForDetail?.links?.map((link) => {
            return link.label;
          }),
        },
      });
    }
  }, [customerForDetail]);

  useEffect(() => {
    if (customerForTypeReportDetail) {
      setTypesOfReport(customerForTypeReportDetail.entities);
    }
  }, [customerForTypeReportDetail]);

  useEffect(() => {
    let _title = id ? "" : "Detail";
    if (customerForDetail && id) {
      _title = `Detail ${customerForDetail.name} `;
    }
    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerForDetail, id]);

  useEffect(() => {
    if (chartSelectedIndex >= 0) {
      readyLink();
    }
  }, [chartSelectedIndex]);

  // const saveProduct = (values) => {
  //   if (!id) {
  //     dispatch(actions.createProduct(values)).then(() => backToProductsList());
  //   } else {
  //     dispatch(actions.updateProduct(values)).then(() => backToProductsList());
  //   }
  // };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const readyLink = () => {
    const link = customerForDetail?.links.find(
      (link) => link.label === data?.options?.labels[chartSelectedIndex]
    );
    history.push(
      `/management/boards-link-users/?id_board=${id}&type=${link.value}`
    );
  };

  const backToProductsList = () => {
    history.push(`/e-commerce/products`);
  };

  const submitForm = (values) => {
    const { typeReport } = values;
    let url;

    switch (typeReport) {
      case "all":
        url = `/management/boards-log/?id_board=${id}&start_date=${formatDate(
          values.initialDate
        )}&end_date=${formatDate(values.finalDate)}`;
        break;
      default:
        const typeToUrl = {
          input: `type=E&module_value=0,1&type_report=${typeReport}`,
          drive: `type=AC&module_value=0,1&type_report=${typeReport}`,
          drive_and_input: `type=AC,E&module_value=0,1&type_report=${typeReport}`,
          temperature: `type=temp_&type_report=${typeReport}`,
          water_monitoring: `type=N_cx,N_res,AC&type_report=${typeReport}`,
          water_tank_buoy_monitoring: `type=Bcx&type_report=${typeReport}`,
          reservoir_buoy_monitoring: `type=Bres&type_report=${typeReport}`,
          water_tank_monitoring: `type=N_cx&type_report=${typeReport}`,
          reservoir_monitoring: `type=N_res&type_report=${typeReport}`,
          scheduling: `type=AG&type_report=${typeReport}`,
        };
        url = `/management/drives/?id_board=${id}&start_date=${formatDate(
          values.initialDate
        )}&end_date=${formatDate(values.finalDate)}&${typeToUrl[typeReport]}`;
    }
    history.push(url);
  };

  function handleModal() {
    setIsShowModal(!isShowModal);
  }

  return (
    <>
      <Modal show={isShowModal} onHide={handleModal}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            initialDate: generateInitialDate(),
            finalDate: currentISODateFormatted(),
            typeReport: "all",
          }}
          onSubmit={(values) => {
            submitForm(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage id="GENERAL.REPORT"></FormattedMessage>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit} className="form form-label-right">
                  <div className="form-group row d-flex align-items-center">
                    <div className="col-lg-4">
                      <select
                        className="form-control"
                        name="typeReport"
                        placeholder="Filter by Status"
                        onChange={(e) => {
                          setFieldValue("typeReport", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.typeReport}
                        key={values.typeReport}
                      >
                        {typesOfReport
                          .filter(
                            (message) =>
                              user.isAdmin ||
                              (!user.isAdmin && message.value !== "all")
                          )
                          .map((message) => (
                            <option key={message.value} value={message.value}>
                              {intl.formatMessage({
                                id: `GENERAL.DROPDOWN.${message.value?.toUpperCase()}`,
                              })}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        onChange={(e) => {
                          setFieldValue("initialDate", e.target.value);
                        }}
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        defaultValue={values.initialDate}
                        style={{ marginBlock: "5px", width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        onChange={(e) => {
                          setFieldValue("finalDate", e.target.value);
                        }}
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        defaultValue={values.finalDate}
                        style={{ marginBlock: "5px", width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="col-lg-4">
                  <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    onClick={handleSubmit}
                  >
                    <FormattedMessage id="GENERAL.CONSULT"></FormattedMessage>
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>

      <Card>
        {actionsLoading && <ModalProgressBar />}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={handleModal}
            >
              <FormattedMessage id="GENERAL.REPORT"></FormattedMessage>
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column">
            <h6>Detalhamento da Placa:</h6>
            <ApexChart
              options={data?.options}
              series={data?.series}
              type="pie"
              width={380}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
