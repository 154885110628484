import React from "react";
import { Alert } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { interactionsSlice } from "../customersSlice";

const { actions } = interactionsSlice;

export function AlertUser({ root }) {
  const dispatch = useDispatch();
  // Customers Redux state
  const { alertUser } = useSelector((state) => {
    return {
      alertUser: state.interactions.alertUser,
    };
  }, shallowEqual);

  const expire = alertUser?.timeout ? alertUser?.timeout : 4000;

  return (
    <>
      {alertUser &&
        [
          "primary",
          "secondary",
          "success",
          "danger",
          "warning",
          "info",
          "light",
          "dark",
        ]
          .filter((variant) => {
            const valid = alertUser.variant === variant;
            console.log(variant, valid);
            if (valid)
              setTimeout(() => {
                dispatch(
                  actions.alert({
                    alertUser: null,
                  })
                );
              }, expire);

            return valid;
          })
          .map((variant, idx) => (
            <Alert key={idx} variant={variant}>
              {alertUser.message}
            </Alert>
          ))}
    </>
  );
}
