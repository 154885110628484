import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSliceBenefitsplans } from "../app/modules/Management/_redux/benefits-plans/customersSlice";
import { customersSliceBoardmonitoring } from "../app/modules/Management/_redux/board-monitoring/customersSlice";
import { customersSliceBoardsBlockList } from "../app/modules/Management/_redux/boards-block-list/customersSlice";
import { customersSliceBoardscontrolboards } from "../app/modules/Management/_redux/boards-control-boards/customersSlice";
import { customersSliceBoardslinkgroups } from "../app/modules/Management/_redux/boards-link-groups/customersSlice";
import { customersSliceBoardslinkpackages } from "../app/modules/Management/_redux/boards-link-packages/customersSlice";
import { boardsLinkTokensSlice } from "../app/modules/Management/_redux/boards-link-tokens/customersSlice";
import { boardsLinkUsersSlice } from "../app/modules/Management/_redux/boards-link-users/customersSlice";
import { boardsLogSlice } from "../app/modules/Management/_redux/boards-log/customersSlice";
import { customersSliceBoardsNotifications } from "../app/modules/Management/_redux/boards-notifications/customersSlice";
import { customersSliceBoardsPermissions } from "../app/modules/Management/_redux/boards-permissions/customersSlice";
import { customersSliceBoardsSends } from "../app/modules/Management/_redux/boards-sends/customersSlice";
import { customersSliceBoards } from "../app/modules/Management/_redux/boards/customersSlice";
import { customersSliceCompanieslinkboards } from "../app/modules/Management/_redux/companies-link-boards/customersSlice";
import { customersSliceCompanieslinkUsers } from "../app/modules/Management/_redux/companies-link-users/customersSlice";
import { customersSliceCompanies } from "../app/modules/Management/_redux/companies/customersSlice";
import { customersSliceDrives } from "../app/modules/Management/_redux/drives/customersSlice";
import { customersSliceGroups } from "../app/modules/Management/_redux/groups/customersSlice";
import { customersSlicePackagesplans } from "../app/modules/Management/_redux/packages-plans/customersSlice";
import { customersSlicePermissions } from "../app/modules/Management/_redux/permissions/customersSlice";
import { productsSlice } from "../app/modules/Management/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/Management/_redux/remarks/remarksSlice";
import { customersSliceRoleslinkpermissions } from "../app/modules/Management/_redux/roles-link-permissions/customersSlice";
import { customersSliceRoles } from "../app/modules/Management/_redux/roles/customersSlice";
import { specificationsSlice } from "../app/modules/Management/_redux/specifications/specificationsSlice";
import { customersSliceTypeActionBoardControl } from "../app/modules/Management/_redux/type-action-board-control/customersSlice";
import { customersSliceTypeBoardControl } from "../app/modules/Management/_redux/type-board-control/customersSlice";
import { customersSliceTypeReport } from "../app/modules/Management/_redux/type-report/customersSlice";
import { customersSliceUserslinkpermissions } from "../app/modules/Management/_redux/users-link-permissions/customersSlice";
import { customersSliceUserslinkroles } from "../app/modules/Management/_redux/users-link-roles/customersSlice";
import { customersSliceUsers } from "../app/modules/Management/_redux/users/customersSlice";
import { interactionsSlice } from "./components/customersSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: customersSliceUsers.reducer,
  boards: customersSliceBoards.reducer,
  benefitsplans: customersSliceBenefitsplans.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  interactions: interactionsSlice.reducer,
  boardsLinkUsers: boardsLinkUsersSlice.reducer,
  boardsLog: boardsLogSlice.reducer,
  boardsLinkTokens: boardsLinkTokensSlice.reducer,
  companies: customersSliceCompanies.reducer,
  roles: customersSliceRoles.reducer,
  permissions: customersSlicePermissions.reducer,
  typeReport: customersSliceTypeReport.reducer,
  typeBoardControl: customersSliceTypeBoardControl.reducer,
  typeActionBoardControl: customersSliceTypeActionBoardControl.reducer,
  userslinkroles: customersSliceUserslinkroles.reducer,
  groups: customersSliceGroups.reducer,
  roleslinkpermissions: customersSliceRoleslinkpermissions.reducer,
  boardslinkgroups: customersSliceBoardslinkgroups.reducer,
  userslinkpermissions: customersSliceUserslinkpermissions.reducer,
  boardscontrolboards: customersSliceBoardscontrolboards.reducer,
  boardsSends: customersSliceBoardsSends.reducer,
  packagesplans: customersSlicePackagesplans.reducer,
  boardslinkpackages: customersSliceBoardslinkpackages.reducer,
  drives: customersSliceDrives.reducer,
  boardsblocklist: customersSliceBoardsBlockList.reducer,
  companieslinkboards: customersSliceCompanieslinkboards.reducer,
  BoardsNotifications: customersSliceBoardsNotifications.reducer,
  companieslinkusers: customersSliceCompanieslinkUsers.reducer,
  boardspermissions: customersSliceBoardsPermissions.reducer,
  boardmonitoring: customersSliceBoardmonitoring.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
