import * as requestFromServer from "./customersCrud";
import { callTypes, customersSliceTypeReport } from "./customersSlice";

//import { interactionsSlice } from "../../../../../redux/components/customersSlice";

const { actions } = customersSliceTypeReport;

export const fetchTypeReportDetail = (id) => (dispatch) => {
  /*if (!id) {
    return dispatch(actions.customerDetail({ customerForTypeReport: undefined }));
  }*/

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllCustomers(id)
    .then((response) => {
      const TypeReportDetail = response.data;
      dispatch(
        actions.customerTypeReportDetail({
          customerForTypeReportDetail: TypeReportDetail,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find TypeReportDetail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

/*export const fetchCustomers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.customersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCustomer = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then((response) => {
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchGenerateToken = (id, queryParams) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.customerGenerateToken({ customerForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerByToken(id, queryParams)
    .then((response) => {
      const customer = response.data;
      dispatch(actions.customerGenerateToken({ customerForEdit: customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then((response) => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resetCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .resetCustomer(id)
    .then(() => {
      dispatch(actions.customerReset({ id }));
      dispatch(
        interactionsSlice.actions.alert({
          callType: callTypes.alert,
          alertUser: {
            message: "Resetado com sucesso",
            variant: "success",
          },
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't reset customer";
      dispatch(
        interactionsSlice.actions.alert({
          error,
          callType: callTypes.alert,
          alertUser: {
            message: "Falha ao tentar resetar a placa!",
            variant: "danger",
          },
        })
      );
    });
};

export const createCustomer = (customerForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation)
    .then((response) => {
      const { customer } = response.data;
      dispatch(actions.customerCreated({ customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearCustom = () => (dispatch) => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.customerClear());
};

export const clearQr = () => (dispatch) => {
  return dispatch(actions.customerClearQr());
};

export const updateCustomer = (customer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(customer)
    .then(() => {
      dispatch(actions.customerUpdated({ customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update customers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const checkInformation = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .checkInformation(id)
    .then((response) => {
      const { customer } = response.data;
      dispatch(actions.customerCheckInformation({ customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};*/
