import React from "react";
import { FormattedMessage } from "react-intl";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  if (touched && !error && label) {
    return (
      <div className="valid-feedback">
        {label} <FormattedMessage id="GENERAL.ENTERED.CORRECT" />
      </div>
    );
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {/* {!customFeedbackLabel && (
        <>
          <FormattedMessage id="GENERAL.PLEASE.ENTER" /> <b>{label}</b>
        </>
      )} */}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback" style={{ display: "block", color: "#f64e60" }}>{error}</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          {/* <FormattedMessage id="GENERAL.PLEASE.SELECT" /> 
          <b>{label}</b> */}
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel,
}) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
