import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {};

export const interactionsSlice = createSlice({
  name: "interactions",
  initialState: initialCustomersState,
  reducers: {
    alert: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.alertUser = action.payload.alertUser;
    },
  },
});
