//import { useCallback } from 'react';
import io from "socket.io-client";

import store from "../../../../../../redux/store";

const REACT_APP_HOST = process.env.REACT_APP_HOST;
console.log(REACT_APP_HOST);

const socketInstances = [];

export const initSocket = ({
  id_board,
  reference = "global"
}) => {
  const {
    auth: { authToken },
  } = store.getState();


  const socket = io(REACT_APP_HOST, {
    query: {
      access_token: authToken,
      id_board
    },
  });

  socket.on("connect", () => {
    console.log("Socket is connected");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected");
  });

  socket.on("connect_failed", function () {
    console.log("Connection Failed");
  });

  const socketObject = {
    id_board,
    instance: socket,
    reference
  }

  const socketIndex = socketInstances.findIndex((currentSocket) => currentSocket?.id_board === id_board);
  if (socketIndex >= 0) {
    if (socketInstances[socketIndex].instance) {
      endSocket({
        id_board
      });
    }

    socketInstances[socketIndex] = socketObject;
    return socketObject;
  }

  socketInstances.push(socketObject)

  return socketObject;
};

export const endSocket = ({
  id_board = undefined,
  persistReference = undefined
}) => {
  if (id_board === undefined && persistReference === undefined) {
    socketInstances.forEach((socketInstance) => {
      socketInstance.instance.disconnect();
    })
    return;
  }

  if (persistReference) {
    const socketDisconnectList = socketInstances.filter((socketInstance) => socketInstance?.reference !== persistReference);
    socketDisconnectList.forEach((socketToBeDisconnect) => {
      if (socketToBeDisconnect?.instance) {
        socketToBeDisconnect.instance.disconnect();
      }
    })

    return;
  }

  const socketIndex = socketInstances.findIndex((socketInstance) => socketInstance?.id_board === id_board);
  if (socketIndex < 0) {
    return;
  }

  if (!socketInstances[socketIndex]?.instance) {
    return;
  }

  socketInstances[socketIndex].instance.disconnect();
};

export const onCallback = ({
  socket = null,
  channel = ["boards_general"],
  callback = () => { },
}) => {

  if (!socket) {
    return;
  }

  channel.map((obj) => {
    socket.on(obj, (msg) => {
      console.log("callback", obj)
      callback(msg);
    });
  });

  socket.on("authFailed", (error) => {
    callback(error);
  });
};
