import axios from "axios";

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "api/me";

export async function login(username, password) {
  if (username && password) {
    let response = await axios
      .post(process.env.REACT_APP_PUBLIC_URL + "/users/authenticate/", {
        username,
        password,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error.response.data;
      });
    console.log(response);
    if (!response?.success) {
      return response;
    }
    if (response?.data?.token) {
      const user = {
        accessToken: response.data.token,
        refreshToken: response.data.token,
      };
      const data = { ...user, password: undefined };
      if (data) {
        return { success: true, status: 200, data };
      }
    }
  }

  return [500];
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken() {
  let response = await axios
    .post(process.env.REACT_APP_PUBLIC_URL + "/users/info/")
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });

  // if(x.accessToken === accessToken){

  // }

  if (response && response.data) {
    return { user: response.data, password: undefined };
  } else {
    //testar se quando da 401 ta aparecendo erro para  usuario (verificar)
    return [401];
  }
}
