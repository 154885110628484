import React from "react";
import { Route, Switch } from "react-router-dom";

import { Demo1Dashboard } from "./Demo1Dashboard";
import { Demo2Dashboard } from "./Demo2Dashboard";

export function Dashboard() {
  return (
    <>
      <Switch>
        <Route path="/dashboard/demo1" component={Demo1Dashboard} />
        <Route path="/dashboard/demo2" component={Demo2Dashboard} />
      </Switch>
    </>
  );
}
