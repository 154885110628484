import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  lastError: null,
  customerForDetail: undefined,
  customerForInfo: undefined,
  customerForFactory: undefined,
  customerForGetData: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
  alert: "alert",
};

export const customersSliceBoards = createSlice({
  name: "boards",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      switch (action.payload.callType) {
        case callTypes.list:
          state.listLoading = false;
          break;
        case callTypes.alert:
          state.actionsLoading = false;
          state.alertUser = action.payload.alertUser;
          console.log(action.payload.alertUser);
          console.log("caiu no alert");
          break;

        default:
          state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    customerDetail: (state, action) => {
      state.actionsLoading = false;
      state.customerForDetail = action.payload.customerForDetail;
      state.error = null;
    },

    // getCustomerById
    customerInfo: (state, action) => {
      state.actionsLoading = false;
      state.customerForInfo = action.payload.customerForInfo;
      state.error = null;
    },

    customerFactory: (state, action) => {
      state.actionsLoading = false;
      state.customerForFactory = action.payload.customerForFactory;
      state.error = null;
    },

    customerGetData: (state, action) => {
      state.actionsLoading = false;
      state.customerForGetData = action.payload.customerForGetData;
      state.error = null;
    },

    customerFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },
    // findCustomers
    customersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCustomer
    customerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.customer);
      state.customer = action.payload.customer;
    },
    // clear
    customerClear: (state, action) => {
      state.customer = undefined;
    },

    // clear
    customerClearQr: (state, action) => {
      state.customerForEdit = undefined;
    },

    // updateCustomer
    customerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.customer.id) {
          return action.payload.customer;
        }
        return entity;
      });
    },

    customerGenerateToken: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;
    },

    // deleteCustomer
    customerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteCustomers
    customersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    customerPermanentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // customersUpdateState
    customersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //
    customerReset: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    customerRestart: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    customerCheckInformation: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.customerForEdit = action.payload.customer;
    },
  },
});
